import {axiosPrivate} from '../interceptors';

export const createNews = data => {
  const url = '/devportal-admin/v3/news-management';
  return axiosPrivate.request({url: url, data: data, method: 'POST'});
};

export const updateNews = data => {
  const url = '/devportal-admin/v3/news-management';
  return axiosPrivate.request({url: url, data: data, method: 'PUT'});
};

export const updateNewsStatus = data => {
  const url = '/devportal-admin/v3/news-management/status';
  return axiosPrivate.request({url: url, data: data, method: 'PUT'});
};

export const deleteNews = newsID => {
  const url = `/devportal-admin/v3/news-management/${encodeURIComponent(newsID)}`;
  return axiosPrivate.request({url: url, method: 'DELETE'});
};

export const getAllNewsItems = (newsID, view) => {
  const url = `/devportal/v3/news-management/get-all-news-items/${encodeURIComponent(newsID)}${view ? '?view=public' : ''}`;
  return axiosPrivate.request({url: url, method: 'GET'});
};

export const getAllNewsList = (query, signal) => {
  const url = `/devportal/v3/news-management${query ? '/?'+query: ''}`;
  return axiosPrivate.request({url: url, method: 'GET', signal: signal});
};

export const getAllBannerImages = (searchKeyword, cancelToken) => {
  const url = `/devportal-admin/v3/banner-images${searchKeyword ? '?search='+searchKeyword: ''}`;
  return axiosPrivate.request({url: url, method: 'GET', cancelToken});
};
